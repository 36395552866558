import * as React from "react"
import Layout from "../../../../components/layout"
import { Seo } from "../../../../components/seo"
import {
  Avatar,
  Breadcrumb,
  Card,
  Collapse,
  Space,
  Tooltip,
  Typography,
} from "antd"
import PageContent from "../../../../components/page-content"
import { graphql, Link, navigate } from "gatsby"
import { JsonDebugger } from "../../../../components/json-debugger"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../../../../utils/html-serializer"
import { collapseTitle, spacer, collapseItem } from "../faq.module.less"
import { RelatedTypeQuickNav } from "../../../../components/related-types/related-type.quick-nav"
import { JsonLd } from "react-schemaorg"
import { FAQPage } from "schema-dts"
const { Title, Text } = Typography
const { Panel } = Collapse

const FAQCategory = ({ data, location: { hash }, ...other }) => {
  if (!data) return null
  // const {
  //   pageResources: { json, ...notJson },
  //   ...rest
  // } = other
  const assumedKey = hash.replace("#", "")

  // return (
  //   <Layout>
  //     <JsonDebugger json={{ data }} />
  //   </Layout>
  // )

  const category = data?.prismicFaqCategory?.data || {}
  const questions =
    data.allPrismicFaq.edges.map(({ node: { data, url, uid } }) => ({
      uid,
      url,
      ...data,
    })) || []

  return (
    <Layout>
      <Seo title={category.title} />
      <JsonLd<FAQPage>
        item={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: questions.map((question) => ({
            "@type": "Question",
            name: question.title.text,
            acceptedAnswer: {
              "@type": "Answer",
              text: RichText.asText(question.faq_answer.richText),
            },
            url: question.url,
            about: {
              "@type": "Category",
              name: category.title,
            },
          })),
        }}
      />
      <PageContent>
        <Space direction="vertical" size="large" className={spacer}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/learn/">Learn</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/learn/faq/">FAQ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{category.title}</Breadcrumb.Item>
          </Breadcrumb>
          <Card title={<Title>{category.title}</Title>}>
            <Collapse
              defaultActiveKey={assumedKey}
              onChange={(keys) => {
                // console.log({ key })
                const lastKey = keys[keys.length - 1]
                // navigate(`#${lastKey}`, { replace: true })
                if (window && window.history) {
                  history.replaceState({}, "", `#${lastKey}`)
                }
              }}
              // accordion
            >
              {questions &&
                questions.length > 0 &&
                questions.map((question) => (
                  <Panel
                    id={question.uid}
                    className={collapseItem}
                    header={question.title.text}
                    // header={
                    //   <Title level={5} className={collapseTitle}>
                    //     {question.title.text}
                    //   </Title>
                    // }
                    key={question.uid}
                    extra={
                      <Link
                        to={question.url}
                        title={`Dedicated page answering the question "${question.title.text}"`}
                      >
                        <Text type="secondary">Share</Text>
                      </Link>
                    }
                  >
                    <RichText
                      render={question.faq_answer.richText}
                      htmlSerializer={htmlSerializer}
                    />
                    <RelatedTypeQuickNav
                      related={question.related.filter(
                        ({ document }) => document.document !== null
                      )}
                    />
                  </Panel>
                ))}
            </Collapse>
          </Card>
          {/* <JsonDebugger json={{ questions, category }} /> */}
        </Space>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  #query FAQCategoryQuery($id: String, $data__category__uid: String) {
  query FAQCategoryQuery($data__category__uid: String) {
    prismicFaqCategory(uid: { eq: $data__category__uid }) {
      uid
      data {
        title
      }
    }
    allPrismicFaq(
      sort: { fields: data___faq_sort }
      filter: { data: { category: { uid: { eq: $data__category__uid } } } }
    ) {
      edges {
        node {
          ...RelatedTypeQuickNav
          url
          uid
          data {
            title {
              text
            }
            faq_answer {
              richText
            }
            related {
              document {
                uid
              }
            }
          }
        }
      }
    }
  }
`

export default FAQCategory
